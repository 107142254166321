import { FormEvent, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import PaymentService from "../services/PaymentService";
import CustomerService from "../services/CustomerService";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { routes } from "../routes";
import PlanService from "../services/PlanService";
import { sanitize } from "dompurify";
import { MdCall, MdMessage, MdOutlineEmail, MdPerson } from "react-icons/md";
import CommandService from "../services/CommandService";
import CommentService from "../services/CommentService";

interface Props {
  planData: {
    id: number;
    title: string;
    subtitle: string;
    price: number;
    description: string;
    images: string[];
    comments: any[];
  };
}

const CatalogueDetail = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  let customerId = useRef("0");
  const { id } = useParams();
  const [firstname, setFirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [message, setMessage] = useState("");

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const navigate = useNavigate();
  const [plan, setPlan] = useState<Props["planData"]>();
  const extractIdFromString = (str: string) => {
    // Trouver l'index du premier caractère non numérique (supposant que l'ID puisse contenir des chiffres et des lettres)
    const index = str.search(/\D/);
    const idStr = str.substring(0, index);
    return idStr;
  };
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    PlanService.get(Number(extractIdFromString(String(id)))).then((res) => {
      setPlan(res.data.plan);
      setLoading(false);
    });
  }, [refresh]);

  const submitRequest = (e: FormEvent) => {
    e.preventDefault();
    setRefresh(true);
    setLoading(true);
    if (!email || !phone || !firstname || !lastname) {
      toast.error("Veuillez remplir tous les champs svp");
      setLoading(false);
    } else {
      CustomerService.create({
        email: email,
        phone: phone,
        firstname: firstname,
        lastname: lastname,
      })
        .then((res) => {
          //customerId.current = res.data.customer.id;
          PaymentService.create({
            amountPaid: plan?.price,
            plan_id: plan?.id,
            transactionId: "noTransaction",
            customer_id: res.data.customer.id,
          })
            .then((result) => {
              setLoading(false);
              toast.success("Demande d'achat effectuée avec succès");
              navigate(routes.catalogue);
            })
            .catch(() => {
              toast.error("Une erreur est survenue. Veuillez reprendre svp");
              setLoading(false);
            });
        })
        .catch(() => {});
    }
  };

  const submitMessage = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (!email || !message || !phone || !fullname) {
      toast.error("Veuillez remplir tous les champs svp");
    } else {
      CommentService.create({
        email: email,
        message: message,
        phone: phone,
        fullname: fullname,
        plan_id: plan?.id,
      })
        .then(() => {
          setRefresh(!refresh);
          toast.success("Votre commentaire a été envoyé avec succès");
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Veuillez entrez des données correctes svp");
        });
    }
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px",
      }}
    >
      <span className="loader"></span>
    </div>
  ) : plan ? (
    <div className="detail_container">
      {/*  The Modal  */}
      <div
        id="myModal"
        className="modal"
        style={openModal ? { display: "block" } : { display: "none" }}
      >
        {/*   Modal content
         */}{" "}
        <div className="modal-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <h2>Payer un plan</h2>

            <span onClick={() => setOpenModal(false)} className="close">
              &times;
            </span>
          </div>
          <div>
            <form onSubmit={submitRequest}>
              <div className="form_input_ctn">
                <input
                  type="text"
                  placeholder="Nom"
                  className="form-control"
                  onChange={(e) => setlastname(e.target.value)}
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="text"
                  placeholder="Prenom"
                  onChange={(e) => setFirstname(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="form-control"
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Téléphone"
                  className="form-control"
                />
              </div>
              <button type="submit" className="cta_btn btn-primary">
                Payer un plan
              </button>
            </form>
          </div>
        </div>
      </div>
      <section className="detail_image">
        <div className="box_container">
          <div className="detail_image_box">
            <Slider {...settings}>
              {plan?.images &&
                plan.images?.map((image, index) => (
                  <div key={index} className="img_ctn">
                    <img
                      src={
                        plan?.images &&
                        process.env.REACT_APP_FILE_BASE_URL + "/" + image
                      }
                      alt="vinctus_building_corporate"
                      className=""
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="detail_section">
        <div className="box_container">
          <h2>{plan.title}</h2>
          <div>
            <span>{plan.subtitle}</span>
            <span>{plan.price + " "} fcfa</span>
          </div>
          <div className="detail_description">
            {
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitize(plan.description),
                }}
              />
            }{" "}
          </div>
        </div>
      </section>

      <section>
        <div
          className="box_container"
          style={{
            paddingBottom: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="cta_btn" onClick={() => setOpenModal(true)}>
            Passez au paiment maintenant
          </button>
        </div>
      </section>
      <section className="comment_section">
        <div className="box_container">
          {/* <hr /> */}
          <div className="comment_form">
            <h2>Commentaires</h2>
            <div className="comment_ctn">
              {plan?.comments &&
                plan.comments?.map((comment, index) => (
                  <div key={index} className="comment">
                    <h6 style={{ fontWeight: 300 }}>{comment?.fullname}</h6>
                    <p>{comment?.message}</p>
                  </div>
                ))}
            </div>
            <form onSubmit={submitMessage}>
              <div className="first_row">
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdPerson />
                  </span>
                  <input
                    type="text"
                    placeholder="Nom complet"
                    className="form-control"
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdOutlineEmail />
                  </span>
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdCall />
                  </span>
                  <input
                    type="number"
                    placeholder="Téléphone"
                    className="form-control"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="form_input_ctn"
                style={{ alignItems: "flex-start" }}
              >
                <span className="form_input_icon">
                  <MdMessage />
                </span>
                <textarea
                  name=""
                  id=""
                  placeholder="Commentaire"
                  className="form-control"
                  rows={5}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type="submit" className="cta_btn btn-primary">
                Laisser un commentaire
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <Navigate to={routes.catalogue} />
  );
};

export default CatalogueDetail;
