import React, { lazy, Suspense } from "react";
import "./styles/main.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WebsiteLayout from "./layouts/WebsiteLayout";
import { routes } from "./routes";
import "./config/axios/config";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import CatalogueDetail from "./pages/CatalogueDetail";
import Project from "./pages/Project";
import RequestPlan from "./pages/RequestPlan";
import Catalogue from "./pages/Catalogue";
import About from "./pages/About";
import Notfound from "./pages/Notfound";

/* const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const CatalogueDetail = lazy(() => import("./pages/CatalogueDetail"));
const Project = lazy(() => import("./pages/Project"));
const RequestPlan = lazy(() => import("./pages/RequestPlan"));
const Catalogue = lazy(() => import("./pages/Catalogue"));
const About = lazy(() => import("./pages/About"));
const Notfound = lazy(() => import("./pages/Notfound")); */
function App() {
  const router = createBrowserRouter([
    {
      element: <WebsiteLayout />,
      children: [
        {
          path: routes.notFound,
          element: <Notfound />,
        },
        {
          path: routes.home,
          element: <Home />,
        },

        {
          path: routes.about,
          element: <About />,
        },
        {
          path: routes.catalogue,
          element: <Catalogue />,
        },
        {
          path: routes.catalogueDetail,
          element: <CatalogueDetail />,
        },
        {
          path: routes.contact,
          element: <Contact />,
        },
        {
          path: routes.project,
          element: <Project />,
        },
        {
          path: routes.notFound,
          element: <Notfound />,
        },
        {
          path: routes.requestPlan,
          element: <RequestPlan />,
        },
        {
          path: routes.privacy,
          element: <Privacy />,
        },
        {
          path: routes.terms,
          element: <Terms />,
        },
      ],
    },
  ]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100Vh",
          }}
        >
          <span className="loader"></span>
         {/*  Chargement... */}
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
