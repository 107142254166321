import { useEffect, useState } from "react";
import BgBanner from "../assets/images/house.jpeg";
import PlanCard from "../components/PlanCard";
import PlanService from "../services/PlanService";

interface Props {
  planData: {
    id: number;
    title: string;
    subtitle: string;
    price: number;
    description: string;
    images: string[];
  }[];
}

const Catalogue = () => {
  const [plans, setPlans] = useState<Props["planData"]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    PlanService.getAll().then((res) => {
      setPlans(res.data.plans);
      setLoading(false);
    });
  }, []);
  return (
    <div className="catalogue_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>Explorez +300 plans pour votre future demeure idéale</h1>
            <p>
              Plongez dans notre collection exclusive de plans de maisons,
              conçus pour répondre à toutes vos aspirations. Que vous rêviez
              d'une villa luxueuse ou d'un duplex moderne, notre catalogue
              propose une variété d'options adaptées à votre style de vie et à
              vos besoins. Parcourez notre catalogue dès maintenant et
              laissez-vous inspirer pour votre prochaine aventure immobilière.
            </p>
          </div>
        </div>
      </section>
      <section className="catalogue_section">
        <div className="box_container">
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="loader"></span>
            </div>
          )}
          <div className="catalogue_grid">
            {!loading &&
              plans?.map((plan, index) => (
                <PlanCard
                  key={index}
                  id={Number(plan.id)}
                  title={plan.title}
                  subtitle={plan.subtitle}
                  images={plan.images}
                  price={plan.price}
                  description={plan.description}
                />
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Catalogue;
