import { Link } from "react-router-dom";
import BgBanner from "../assets/images/privacy.jpeg";
import { routes } from "../routes";

const Privacy = () => {
  return (
    <div className="privacy_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>Conditions Générales d'Utilisation</h1>
            <p>
              Chez Vinctus Building Corporate, nous accordons une grande
              importance à la confidentialité et à la sécurité des informations
              de nos utilisateurs. Cette politique de confidentialité décrit
              comment nous recueillons, utilisons et protégeons les données
              personnelles que vous nous fournissez lorsque vous utilisez notre
              site web.
            </p>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">
              Collecte et Utilisation des Informations
            </div>
            <div className="privacy_desc">
              Lorsque vous visitez notre site web, nous pouvons recueillir
              certaines informations personnelles vous concernant, telles que
              votre nom, votre adresse e-mail et votre adresse IP. Ces
              informations sont collectées dans le but de vous fournir une
              expérience personnalisée sur notre site, de répondre à vos
              demandes et de vous informer sur nos produits et services. Nous
              utilisons également des cookies et d'autres technologies de suivi
              pour collecter des informations sur votre utilisation de notre
              site web, telles que les pages que vous consultez, les liens sur
              lesquels vous cliquez et le temps que vous passez sur notre site.
              Ces informations nous aident à améliorer notre site web et à mieux
              comprendre les besoins de nos utilisateurs.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Partage des Informations</div>
            <div className="privacy_desc">
              Nous ne partageons pas vos informations personnelles avec des
              tiers, sauf dans les cas suivants :
              <ul>
                <li>Avec votre consentement explicite.</li>
                <li>
                  Pour répondre à des exigences légales ou pour protéger nos
                  droits, notre propriété ou notre sécurité, ainsi que ceux de
                  nos utilisateurs ou de tiers.
                </li>
                <li>
                  Dans le cadre d'une fusion, d'une acquisition ou d'une vente
                  d'actifs, où vos informations pourraient être transférées en
                  tant qu'actif de l'entreprise.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Sécurité des données</div>
            <div className="privacy_desc">
              Nous mettons en œuvre des mesures de sécurité techniques,
              administratives et physiques appropriées pour protéger vos
              informations personnelles contre tout accès non autorisé, toute
              utilisation abusive ou toute divulgation non autorisée.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Liens vers des Sites Tiers</div>
            <div className="privacy_desc">
              Notre site web peut contenir des liens vers des sites web de
              tiers. Nous ne sommes pas responsables des pratiques de
              confidentialité ou du contenu de ces sites web. Nous vous
              encourageons à lire les politiques de confidentialité de ces sites
              web avant de fournir vos informations personnelles.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">
              Modifications de la Politique de Confidentialité
            </div>
            <div className="privacy_desc">
              Nous nous réservons le droit de mettre à jour, de modifier ou de
              remplacer notre politique de confidentialité à tout moment. Les
              modifications prendront effet dès leur publication sur cette page.
              Nous vous encourageons à consulter régulièrement cette page pour
              rester informé de tout changement. En continuant à utiliser notre
              site web après la publication de toute modification, vous acceptez
              les conditions de notre politique de confidentialité mise à jour.
            </div>
          </div>
        </div>
      </section>

      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Contact</div>
            <div className="privacy_desc">
              Si vous avez des questions concernant notre politique de
              confidentialité, veuillez nous contacter à l'adresse suivante :
              contact@vinctusbc.com ou via{" "}
              <Link to={routes.contact}>ce formulaire</Link>.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
