import { Link } from "react-router-dom";
import BgBanner from "../assets/images/terms.jpeg";
import { routes } from "../routes";

const Terms = () => {
  return (
    <div className="privacy_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>Conditions Générales d'Utilisation</h1>
            <p>
              Merci de lire attentivement les présentes conditions générales
              d'utilisation avant d'accéder ou d'utiliser notre plateforme
              Vinctus Building Corporate. En accédant ou en notre plateforme,
              vous acceptez d'être lié par ces CGU. Si vous n'acceptez pas
              toutes les conditions et modalités de ces CGU, veuillez ne pas
              l'utiliser.
            </p>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Commandes et Achats</div>
            <div className="privacy_desc">
              Lorsque vous passez une commande ou effectuez un achat sur notre
              plateforme, nous collectons certaines informations personnelles
              nécessaires à la finalisation de la transaction, telles que votre
              nom, votre adresse de livraison et vos informations de paiement.
              Ces informations sont utilisées uniquement dans le cadre de votre
              commande et ne sont pas partagées avec des tiers, sauf dans les
              cas prévus par la loi ou avec votre consentement explicite.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Contenu</div>
            <div className="privacy_desc">
              Vous êtes seul responsable du contenu que vous publiez,
              téléchargez ou affichez sur le Service. Vous acceptez de ne pas
              publier de contenu qui viole les droits d'auteur, les marques de
              commerce, les droits de confidentialité ou tout autre droit de
              tiers. Vinctus Building Corporate se réserve le droit de supprimer
              tout contenu jugé inapproprié ou en violation de ces CGU.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Propriété Intellectuelle</div>
            <div className="privacy_desc">
              Le Service et son contenu, y compris mais sans s'y limiter, les
              textes, graphiques, logos, images, vidéos, logiciels et codes
              source, sont la propriété de Vinctus Building Corporate ou de ses
              concédants de licence et sont protégés par les lois sur la
              propriété intellectuelle. Vous vous engagez à respecter tous les
              droits de propriété intellectuelle associés au Service.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Limitation de Responsabilité</div>
            <div className="privacy_desc">
              Dans toute la mesure permise par la loi applicable, Vinctus
              Building Corporate et ses dirigeants, employés, agents,
              partenaires et concédants de licence ne seront en aucun cas
              responsables de tout dommage indirect, accessoire, spécial,
              consécutif ou punitif résultant de l'utilisation ou de
              l'incapacité à utiliser le Service.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Modifications des CGU</div>
            <div className="privacy_desc">
              Vinctus Building Corporate se réserve le droit de mettre à jour,
              de modifier ou de remplacer les CGU à tout moment. Les
              modifications prendront effet dès leur publication sur le Service.
              Il est de votre responsabilité de consulter régulièrement les CGU
              pour être informé de toute modification. En continuant d'accéder
              ou d'utiliser le Service après la publication de toute
              modification, vous acceptez d'être lié par les CGU modifiées.
            </div>
          </div>
        </div>
      </section>
      <section className="privacy_container">
        <div className="box_container">
          <div className="privacy_wraper">
            <div className="privacy_title">Contact</div>
            <div className="privacy_desc">
              Si vous avez des questions concernant les présentes CGU, veuillez
              nous contacter à l'adresse suivante : contact@vinctusbc.com ou via{" "}
              <Link to={routes.contact}>ce formulaire</Link>.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
