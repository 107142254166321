interface Props {
  title: string;
  image: string;
  description: string;
}
const ProjectCard = ({ title, image, description }: Props) => {
  return (
    <div
      className="project_card animate__backInUp"
      style={{
        backgroundImage: `url(${
          process.env.REACT_APP_FILE_BASE_URL + "/" + image
        })`,
      }}
    >
      <div className="project_card_description">
        <h6>{title}</h6>
        <p>{description}</p>
        {/* <button className="cta_btn">Voir</button> */}
      </div>
      ;
    </div>
  );
};

export default ProjectCard;
