import axios from "axios"
class CustomerService {
    getAll() {
        return axios.get(`/customers`);
    }
    get(id:number) {
        return axios.get(`/customers/${id}`);
    }
    create(data:any) {
        return axios.post(`/customers`, data);
    } 
    update(id:number, data:any) {
        return axios.put(`/customers/${id}`, data);
    }
    delete(id:number) {
        return axios.delete(`/customers/${id}`);
    }
}
export default new CustomerService();