import {
  FaCheck
} from "react-icons/fa";
import Team from "../assets/images/team.jpeg";
import Plan from "../assets/images/plan.jpeg";
import History from "../assets/images/history.jpg";
import Office from "../assets/images/office.jpg";
import Construction from "../assets/images/construction.jpeg";
import { routes } from "../routes";
import { useNavigate } from "react-router-dom";
import BelOasis from "../assets/images/BelOasis.png";
import BFR from "../assets/images/bfr.png";


const About = () => {
  const navigate = useNavigate();
  return (
    <div className="about_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${Team})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>A propos de nous</h1>
            {/* <p>
              Plongez dans notre collection exclusive de plans de maisons,
              conçus pour répondre à toutes vos aspirations. Que vous rêviez
              d'une villa luxueuse ou d'un duplex moderne, notre catalogue
              propose une variété d'options adaptées à votre style de vie et à
              vos besoins. Parcourez notre catalogue dès maintenant et
              laissez-vous inspirer pour votre prochaine aventure immobilière.
            </p> */}
          </div>
        </div>
      </section>{" "}
      <section className="container_section">
        <div className="box_container">
          <div className="container_wraper">
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                A propos de nous
              </h2>
              <p className="animate__animated animate__bounce">
                Vinctus Building Corporate est votre destination ultime pour
                trouver des plans de maisons uniques et personnalisables conçus
                par des architectes de renom. Chez Vinctus Building Corporate,
                nous croyons en la puissance de l'architecture pour transformer
                des idées en réalité et créer des espaces qui inspirent,
                enrichissent et améliorent la vie. Notre entreprise incarne
                l'excellence architecturale, l'innovation et l'engagement envers
                la satisfaction client. Que vous construisiez votre première
                maison, que vous recherchiez une rénovation ou que vous soyez un
                professionnel de la construction, nous sommes là pour vous aider
                à concrétiser votre projet. Explorez notre site dès maintenant
                pour commencer votre voyage vers la maison de vos rêves.
              </p>
              <div style={{ marginTop: "30px" }}>
                <button
                  className="cta_btn"
                  onClick={() => navigate(routes.catalogue)}
                >
                  Explorer notre catalogue
                </button>
              </div>
            </div>
            <div className="container_image">
              <img src={Plan} className="" />
            </div>
          </div>
        </div>
      </section>
      <section className="value_container">
        <div className="box_container">
          <h2>Nos valeurs</h2>
          <div className="value_section">
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>
              <h4 className="value_section_item_title">Excellence</h4>
              <p>
                Nous nous engageons à atteindre l'excellence dans toutes nos
                réalisations, en offrant des solutions architecturales de haute
                qualité qui dépassent les attentes de nos clients.
              </p>
            </div>
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>
              <h4 className="value_section_item_title">Innovation</h4>
              <p>
                Nous sommes constamment à la recherche de nouvelles idées et de
                nouvelles approches pour répondre aux besoins changeants de nos
                clients et pour repousser les limites de la conception
                architecturale.
              </p>
            </div>
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>{" "}
              <h4 className="value_section_item_title">Durabilité</h4>
              <p>
                Nous sommes engagés envers la durabilité et la responsabilité
                environnementale. Nous intégrons des pratiques et des matériaux
                écologiques dans nos conceptions pour minimiser notre empreinte
                carbone et promouvoir un avenir plus durable.
              </p>
            </div>
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>{" "}
              <h4 className="value_section_item_title">
                Engagement envers le Client
              </h4>
              <p>
                La satisfaction de nos clients est notre priorité absolue. Nous
                sommes dévoués à comprendre leurs besoins et à leur fournir des
                solutions sur mesure qui surpassent leurs attentes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container_section">
        <div className="box_container">
          <div className="container_wraper" style={{ marginBottom: "80px" }}>
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                Notre mission
              </h2>
              <p className="animate__animated animate__bounce">
                Nous nous engageons à offrir à nos clients des solutions
                architecturales de haute qualité, innovantes et adaptées à leurs
                besoins spécifiques. Notre mission est de créer des espaces
                exceptionnels qui répondent aux aspirations de nos clients et
                qui repoussent les limites de la conception architecturale.
                Chaque projet que nous entreprenons est une opportunité de
                concrétiser les rêves et les aspirations de nos clients. Nous
                mettons notre expertise, notre créativité et notre passion au
                service de la réalisation de leurs projets les plus ambitieux.
              </p>
            </div>
            <div className="container_image">
              <img src={Office} className="" />
            </div>
          </div>
          <div
            className="container_wraper"
            style={{ flexDirection: "row-reverse" }}
          >
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                Notre historique
              </h2>
              <p className="animate__animated animate__bounce">
                Depuis notre création, nous nous engageons à fournir à nos
                clients des solutions architecturales de haute qualité et
                innovantes. Notre passion pour l'architecture et notre
                dévouement à la satisfaction client sont au cœur de tout ce que
                nous faisons. Nous collaborons étroitement avec vous à chaque
                étape du processus, en veillant à ce que vos besoins et vos
                aspirations soient pleinement pris en compte. Que vous
                envisagiez de construire une maison familiale chaleureuse, un
                bâtiment commercial emblématique ou un projet de rénovation
                innovant, notre équipe est prête à relever le défi avec
                enthousiasme et détermination.
              </p>
              <div style={{ marginTop: "30px" }}>
                <button
                  className="cta_btn"
                  onClick={() => navigate(routes.catalogue)}
                >
                  Commander un plan
                </button>
              </div>
            </div>
            <div className="container_image">
              <img src={Construction} className="" />
            </div>
          </div>
        </div>
      </section>
      <section className="our_value_container">
        <div
          className="box_container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Pourquoi nous choisir ?</h2>
          <div className="our_value_wraper">
            <div className="our_value">
              <span>1</span>
              <div>
                <FaCheck />
              </div>
              <div className="our_value_desc">
                Expertise professionnelle et créative
              </div>
            </div>
            <div className="our_value">
              <span>2</span>
              <div>
                {" "}
                <FaCheck />
              </div>
              <div className="our_value_desc">
                Engagement envers la satisfaction client
              </div>
            </div>
            <div className="our_value">
              <span>3</span>
              <div>
                {" "}
                <FaCheck />
              </div>
              <div className="our_value_desc">
                Solutions architecturales innovantes et durables
              </div>
            </div>
            <div className="our_value">
              <span>4</span>
              <div>
                <FaCheck />
              </div>
              <div className="our_value_desc">
                Collaboration étroite avec nos clients à chaque étape
              </div>
            </div>
            {/* <div className="our_value">
              <span>5</span>
              <div>
                <FaCheck />
              </div>
              <div className="our_value_desc">
                Passion pour l'architecture et l'innovation
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="stats_container">
        <div className="box_container">
          <div className="stats_section">
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Architecture</h4>
              <ul>
                <li>Plannification et Développement</li>
                <li>Gestion de projet</li>
                <li>Ingenierie des structures</li>
              </ul>
            </div>
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Modélisation 3D</h4>
              <ul>
                <li>Rendu Intérieur</li>
                <li>Visualisation</li>
                <li>Architectures VR</li>
              </ul>
            </div>
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Design</h4>
              <ul>
                <li>Design d'intérieur</li>
                <li>Conception architecturale</li>
                <li>Détail techniques</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="team_container">
        <div className="box_container">
          <div className="team_section">
            <h2>Notre équipe</h2>
            <div className="team-member-wraper">
              <div className="team-member" data-aos="zoom-in-left">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-right">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-down">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-up">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-left">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-right">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-down">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-up">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="container_section">
        <div className="box_container">
          <div className="container_wraper">
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                Notre équipe
              </h2>
              <p className="animate__animated animate__bounce">
                Chez Vinctus Building Corporate, notre équipe est composée
                d'architectes talentueux et passionnés, déterminés à donner vie
                à vos projets les plus ambitieux. Chaque membre de notre équipe
                apporte son expertise unique et sa créativité à chaque projet,
                garantissant des résultats exceptionnels à chaque fois. Notre
                équipe est dévouée à offrir à nos clients une expérience
                exceptionnelle, de la conception initiale à la réalisation
                finale. Nous collaborons étroitement avec nos clients à chaque
                étape du processus, en veillant à ce que leurs besoins et leurs
                aspirations soient pleinement pris en compte.
              </p>
              <div style={{ marginTop: "30px" }}>
                <button
                  className="cta_btn"
                  onClick={() => navigate(routes.contact)}
                >
                  Contactez-nous
                </button>
              </div>
            </div>
            <div className="container_image">
              <img src={History} className="" />
            </div>
          </div>
        </div>
      </section>
      <section className="partner-section-container">
        <div className="box_container">
          <div className="partner-section">
            <div className="partner">
              <img src={BelOasis} alt="association_parlons_droit_et_societe" />
            </div>
            <div className="partner">
              <img src={BFR} alt="aa_consulting" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
