import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaTiktok, FaInstagram } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { routes } from "../routes";

const Footer = () => {
  return (
    <div className="footer_container">
      <section className="footer_section">
        <div className="box_container">
          <div className="footer_wraper">
            <div className="footer_item">
              <h4 className="footer_item_title">Adresse</h4>
              <p>Cotonou, Haie Vive, Rue 1442 Lorem ipsum dolor sit amet.</p>
            </div>
            <div className="footer_item">
              <h4 className="footer_item_title">Contacts</h4>
              <ul>
                <li>
                  <span style={{ color: "", width: "28%" }}>Tel.</span>
                  <a
                    href="tel:0022998839962"
                    target="_blank"
                    style={{ color: "#fff", width: "71%" }}
                    rel="noreferrer"
                  >
                    +22998839962
                  </a>
                </li>
                <li>
                  <span style={{ color: "", width: "28%" }}>Email.</span>
                  <a
                    href="mailto:contact@vinctusbc.com"
                    style={{ color: "#fff", width: "71%" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@vinctusbc.com
                  </a>
                </li>
              </ul>
              <div className="icon_ctn">
                <a
                  href="https://www.facebook.com/profile.php?id=100063803235327"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="http://tiktok.com/@vinctusbuildingcorporate"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
                <a
                  href="https://www.instagram.com/vinctusbuildingcorporate?igsh=ZGUzMzM3NWJiOQ=="
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://twitter.com/VinctusC"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
            </div>
            <div className="footer_item">
              <h4 className="footer_item_title">Newsletter</h4>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdOutlineEmail />
                  </span>
                  <input
                    type="email"
                    placeholder="Entrer votre adresse email"
                    className="form-control"
                  />
                </div>
                <button type="submit" className="cta_btn btn-primary">
                  S'abonner
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_copy_right">
        <div className="box_container">
          <div className="footer_copy_right_wraper">
            <div>
              <span>
                &copy;{" "}
                {new Date().getFullYear() +
                  " Vinctus Building Corporate. Tous droits réservés"}{" "}
              </span>
            </div>
            <div className="footer_link_container">
              <div className="footer_link">
                <Link to={routes.terms}>Conditions générales</Link>
              </div>
              <div className="footer_link">
                <Link to={routes.privacy}>Politiques de confidentialité</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
