import axios from "axios";
class CommandService {
  getAll() {
    return axios.get("/commands");
  }
  get(id:number) {
    return axios.get(`/commands/${id}`);
  }
  create(data:any) {
    return axios.post("/commands", data);
  }
  update(id:number, data:any) {
    return axios.put(`/commands/${id}`, data);
  }
  delete(id:number) {
    return axios.delete(`/commands/${id}`);
  }
}
export default new CommandService();
