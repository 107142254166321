import { Link, useNavigate } from "react-router-dom";
import { sanitize } from 'dompurify';

interface Props {
  id:number,
  title: string;
  subtitle: string;
  price: number;
  description: string;
  images: string[];
}

const PlanCard = ({ id, title, subtitle, price, description, images }: Props) => {
    const navigate = useNavigate()
     const generateStringWithId = (id:any) => {
        // Générer 8 caractères aléatoires
        //const randomChars = Math.random().toString(36).substring(2, 10);
        const randomChars = Array.from({ length: 8 }, () => String.fromCharCode(65 + Math.floor(Math.random() * 26)));
        // Concaténer l'ID avec les caractères aléatoires
        const result = id + randomChars.join('');
        // Concaténer l'ID avec les caractères aléatoires
        //const result = id + randomChars;
        return result;
    }
  return (
    <div className="plan_card" onClick={()=> navigate(`/nos-plans/${generateStringWithId(id)}`)}>
      <Link to={images && process.env.REACT_APP_FILE_BASE_URL +"/" + images[0]} target="_blank">
        <div className="plan_card_image">
          <img src={images && process.env.REACT_APP_FILE_BASE_URL +"/" + images[0]} alt="vinctus_building_corporate_plan" />
          <span className="price">{price + " euro"}</span>
        </div>
      </Link>
      
      <div className="plan_card_description">
        <h6>{title}</h6>
        <p dangerouslySetInnerHTML={{__html: sanitize(description)}} />
      </div>
    </div>
  );
};

export default PlanCard;
