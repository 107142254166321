import { MdCall, MdMessage, MdOutlineEmail, MdPerson } from "react-icons/md";
import BgBanner from "../assets/images/banner.jpg";
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
} from "react-icons/fa6";
import { FormEvent, useState } from "react";
import InboxService from "../services/InboxService";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const submitMessage = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!email || !message || !phone || !fullname) {
      toast.error("Veuillez remplir tous les champs svp");
      setLoading(false);
    } else {
      InboxService.create({
        email: email,
        message: message,
        phone: phone,
        fullname: fullname,
      })
        .then(() => {
          toast.success("Votre message a été envoyé avec succès");
          setLoading(false);
        })
        .catch(() => {
          toast.error("Veuillez entrez des données correctes svp");
          setLoading(false);
        });
    }
  };
  return (
    <div className="contact_container">
      <ToastContainer />
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>Contacts</h1>
            <p>Consultez nos contacts ou écrivez-nous un message</p>
          </div>
        </div>
      </section>

      <section className="contact_section">
        <div className="box_container">
          <div className="contact_info">
            <div className="contact_item">
              <h5 className="contact_item_title">Email & téléphone</h5>
              <ul>
                <li>
                  <a
                    href="mailto:contact@vinctusbc.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    contact@vinctusbc.com
                  </a>
                </li>
                <li>
                  <a href="tel:0022998839962" target="_blank" rel="noreferrer">
                    +22998839962
                  </a>
                </li>
              </ul>
            </div>
            <div className="contact_item">
              <h5 className="contact_item_title">Adresse</h5>
              <p>Cotonou, Haie Vive, Rue 1442 Lorem ipsum dolor sit amet.</p>
            </div>
            <div className="contact_item">
              <h5 className="contact_item_title">Réseaux sociaux</h5>
              <div className="icon_ctn">
                <a
                  href="https://www.facebook.com/profile.php?id=100063803235327"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="http://tiktok.com/@vinctusbuildingcorporate"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
                <a
                  href="https://www.instagram.com/vinctusbuildingcorporate?igsh=ZGUzMzM3NWJiOQ=="
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://twitter.com/VinctusC"
                  target="_blank"
                  className="icon_item"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      ) : (
        <section className="contact_form">
          <div className="box_container">
            <h2>Prenez contact avec nous</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quasi
              inventore, corporis quibusdam sint nihil dignissimos?
            </p>
            <form onSubmit={submitMessage}>
              <div className="first_row">
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdPerson />
                  </span>
                  <input
                    type="text"
                    placeholder="Nom complet"
                    className="form-control"
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdOutlineEmail />
                  </span>
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form_input_ctn">
                  <span className="form_input_icon">
                    <MdCall />
                  </span>
                  <input
                    type="number"
                    placeholder="Téléphone"
                    className="form-control"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="form_input_ctn"
                style={{ alignItems: "flex-start" }}
              >
                <span className="form_input_icon">
                  <MdMessage />
                </span>
                <textarea
                  name=""
                  id=""
                  placeholder="Message"
                  className="form-control"
                  rows={5}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type="submit" className="cta_btn btn-primary">
                Laisser un message
              </button>
            </form>
          </div>
        </section>
      )}
    </div>
  );
};

export default Contact;
