import axios from "axios"
class PaymentService {
    getAll() {
        return axios.get(`/payments`);
    }
    get(id:number) {
        return axios.get(`/payments/${id}`);
    }
    create(data:any) {
        return axios.post(`/payments`, data);
    } 
    update(id:number, data:any) {
        return axios.put(`/payments/${id}`, data);
    }
    delete(id:number) {
        return axios.delete(`/payments/${id}`);
    }
}
export default new PaymentService();