import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
} from "react-icons/fa6";
import BgBanner from "../assets/images/house.jpeg";
import { FormEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import CommandService from "../services/CommandService";
import { useKKiaPay } from "kkiapay-react";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";

const RequestPlan = () => {
  let commandId = useRef("0");
  const [firstname, setFirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [surface, setSurface] = useState("");
  const [budget, setBudget] = useState("");
  const [loading, setLoading] = useState(false);
  const [canShow, setCanShow] = useState("");
  const navigate = useNavigate();

  const submitRequest = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (
      !email ||
      !description ||
      !phone ||
      !firstname ||
      !lastname ||
      !surface ||
      !budget ||
      !canShow
    ) {
      setLoading(false);
      toast.error("Veuillez remplir tous les champs svp");
    } else {
      CommandService.create({
        email: email,
        description: description,
        phone: phone,
        firstname: firstname,
        lastname: lastname,
        surface: surface,
        budget: budget,
        amount: parseInt(surface) * 600,
        showAfter: canShow == "true" ? true : false,
      })
        .then((res) => {
          setLoading(false);
          //open(parseInt(surface) * 600);
          toast.success("Commande effectuée avec succès");
          navigate(routes.catalogue);
          //commandId.current = res.data.command.id;
        })
        .catch(() => {
          setLoading(false);
          toast.error("Veuillez entrez des données correctes svp");
        });
    }
  };
  return (
    <div className="request_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1>Prêt à construire sur votre terrain ?</h1>
            <p>
              Vous aviez une idée de construction en tête mais vous n'aviez pas
              trouver le plan adéquat pour vous ? Remplissez le formulaire
              ci-dessous pour avoir un plan sur mesure et donner vie à votre
              projet immobilier.
            </p>
          </div>
        </div>
      </section>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      ) : (
        <section className="request_form">
          <div className="box_container">
            <h2>Demander un plan</h2>
            <p>
              Veuillez remplir le formulaire ci-dessous pour nous fournir les
              détails nécessaires à la réalisation du plan qui répondra
              parfaitement à vos besoins et à vos préférences
            </p>
            <form onSubmit={submitRequest}>
              <div className="form_input_ctn">
                <input
                  type="text"
                  placeholder="Nom"
                  className="form-control"
                  onChange={(e) => setlastname(e.target.value)}
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="text"
                  placeholder="Prenom"
                  onChange={(e) => setFirstname(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="form-control"
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Téléphone"
                  className="form-control"
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="number"
                  placeholder="Taille estimée du projet (en m2)"
                  className="form-control"
                  onChange={(e) => setSurface(e.target.value)}
                />
              </div>
              <div className="form_input_ctn">
                <input
                  type="number"
                  placeholder="Budget prévisionnel (en fcfa)"
                  onChange={(e) => setBudget(e.target.value)}
                  className="form-control"
                />
              </div>
              <label
                htmlFor=""
                style={{
                  display: "block",
                }}
              >
                Acceptez-vous que ce plan soit ajouté au catalogue public sur
                les plateformes de Vinctus Building Corporate ?
              </label>
              <div className="form_input_ctn">
                <select
                  name=""
                  id=""
                  onChange={(e) => setCanShow(e.target.value)}
                >
                  <option disabled>Veuillez choisir une option</option>
                  <option value={"true"}>Oui</option>
                  <option value={"false"}>Non</option>
                </select>
              </div>
              <div
                className="form_input_ctn"
                style={{ alignItems: "flex-start" }}
              >
                <textarea
                  name=""
                  id=""
                  placeholder="Description du projet"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                ></textarea>
              </div>
              {surface && (
                <div className="price_warning">
                  Votre commande vous coutera{" "}
                  {canShow  == "true" ? parseInt(surface) * 3 : parseInt(surface) * 6} euro
                  à raison de {canShow  == "true" ? "3 euros" : "6 euro"} par m2{" "}
                </div>
              )}
              <button type="submit" className="cta_btn btn-primary">
                Commander un plan
              </button>
            </form>
          </div>
        </section>
      )}
    </div>
  );
};

export default RequestPlan;
