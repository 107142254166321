import BgBanner from "../assets/images/home_bg.jpeg";
import BelOasis from "../assets/images/BelOasis.png";
import BFR from "../assets/images/bfr.png";
import ProjectCard from "../components/ProjectCard";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useEffect, useState } from "react";
import PlanService from "../services/PlanService";
import ProjectService from "../services/ProjectService";
import TestimonyService from "../services/TestimonyService";
import Slider from "react-slick";
import PlanCard from "../components/PlanCard";
import { FaCheck, FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";
import History from "../assets/images/history.jpg";
import Plan from "../assets/images/plan.jpeg";

interface Props {
  planData: {
    id: number;
    title: string;
    subtitle: string;
    price: number;
    description: string;
    images: string[];
  }[];
  projectData: {
    id: number;
    title: string;
    description: string;
    cover_img: string;
  }[];
  testimonyData: {
    id: number;
    fullname: string;
    title: string;
    message: string;
    cover_img: string;
  }[];
}
const Home = () => {
  const [plans, setPlans] = useState<Props["planData"]>([]);
  const [testimonies, setTestimonies] = useState<Props["testimonyData"]>([]);
  const [projects, setProjects] = useState<Props["projectData"]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  useEffect(() => {
    setLoading(true);
    PlanService.getAll().then((res) => {
      setPlans(res.data.plans);
      setLoading(false);
    });
    ProjectService.getAll().then((res) => {
      setProjects(res.data.projects);
      setLoading(false);
    });
    TestimonyService.getAll().then((res) => {
      setTestimonies(res.data.testimonies);
      setLoading(false);
    });
  }, []);

  return (
    <div className="home_container">
      <section
        className="banner-container"
        style={{
          backgroundImage: `url(${BgBanner})`,
        }}
      >
        <div className="box_container">
          <div className="banner_description">
            <h1 className="animate__animated animate__bounce">
              Vinctus Building Corporate
            </h1>
            <p className="animate__animated animate__bounce">
              Plus de 20 d'experience dans la combinaison de traditions et
              d’innovations, Vinctus Building Corporate crée des environnements
              qui inspirent la façon dont les gens vivent, apprennent,
              guérissent, travaillent et jouent..
            </p>
          </div>
        </div>
      </section>
      <section className="container_section">
        <div className="box_container">
          <div className="container_wraper">
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                A propos de nous
              </h2>
              <p className="animate__animated animate__bounce">
                Vinctus Building Corporate est votre destination ultime pour
                trouver des plans de maisons uniques et personnalisables conçus
                par des architectes de renom. Chez Vinctus Building Corporate,
                nous croyons en la puissance de l'architecture pour transformer
                des idées en réalité et créer des espaces qui inspirent,
                enrichissent et améliorent la vie. Notre entreprise incarne
                l'excellence architecturale, l'innovation et l'engagement envers
                la satisfaction client. Que vous construisiez votre première
                maison, que vous recherchiez une rénovation ou que vous soyez un
                professionnel de la construction, nous sommes là pour vous aider
                à concrétiser votre projet. Explorez notre site dès maintenant
                pour commencer votre voyage vers la maison de vos rêves.
              </p>
              <div style={{ marginTop: "30px" }}>
                <button
                  className="cta_btn"
                  onClick={() => navigate(routes.about)}
                >
                  En savoir plus
                </button>
              </div>
            </div>
            <div className="container_image">
              <img src={Plan} className="" />
            </div>
          </div>
        </div>
      </section>

      {projects.length > 0 && (
        <section className="project_container">
          <div className="box_container">
            <h2 className="animate__backInDown">
              Quelques une de nos dernieres Réalisations
            </h2>
            <div className="project_grid">
              {projects.map((project, index) => (
                <ProjectCard
                  key={index}
                  title={project.title}
                  description={project.description}
                  image={project.cover_img}
                />
              ))}
            </div>
            <div>
              <button
                className="cta_btn"
                onClick={() => navigate(routes.project)}
              >
                Voir tous nos projets réalisés
              </button>{" "}
            </div>
          </div>
        </section>
      )}
      <section className="value_container">
        <div className="box_container">
          <h2>Ce que nous vous proposons</h2>
          <div className="value_section">
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>
              <h4 className="value_section_item_title">Des Plans Sur Mesure</h4>
              <p>
                Besoin d'une solution sur mesure ? Notre équipe d'architectes
                expérimentés est là pour transformer vos idées en réalité.
                Contactez-nous dès aujourd'hui pour discuter de vos besoins
                spécifiques et commencer à concevoir la maison de vos rêves.
              </p>
            </div>
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>
              <h4 className="value_section_item_title">
                Inspirations Architecturales
              </h4>
              <p>
                Explorez notre galerie de photos pour trouver l'inspiration pour
                votre prochain projet. Des maisons contemporaines aux designs
                intemporels, laissez-vous séduire par notre sélection de
                réalisations qui illustrent la diversité et la qualité de notre
                travail.
              </p>
            </div>
            <div className="value_section_item">
              <span className="value_icon">
                <FaCheck />
              </span>{" "}
              <h4 className="value_section_item_title">Facile et Pratique</h4>
              <p>
                Commander un plan de maison n'a jamais été aussi simple.
                Parcourez notre catalogue en ligne, sélectionnez le plan qui
                vous convient, personnalisez-le selon vos préférences et passez
                votre commande en quelques clics. Notre équipe est là pour vous
                accompagner à chaque étape du processus.
              </p>
            </div>
          </div>
        </div>
      </section>
      {plans.length > 0 && (
        <section className="project_container">
          <div className="box_container">
            <h2 className="animate__backInDown">
              Explorez notre Collection de Plans
            </h2>
            <div className="project_grid">
              {plans.slice(0, 8).map((plan, index) => (
                <PlanCard
                  key={index}
                  id={Number(plan.id)}
                  title={plan.title}
                  subtitle={plan.subtitle}
                  images={plan.images}
                  price={plan.price}
                  description={plan.description}
                />
              ))}
            </div>
            <div style={{ marginTop: "30px" }}>
              <button
                className="cta_btn"
                onClick={() => navigate(routes.catalogue)}
              >
                Voir tous nos plans
              </button>{" "}
            </div>
          </div>
        </section>
      )}

      <section className="stats_container">
        <div className="box_container">
          <div className="stats_section">
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Architecture</h4>
              <ul>
                <li>Plannification et Développement</li>
                <li>Gestion de projet</li>
                <li>Ingenierie des structures</li>
              </ul>
            </div>
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Modélisation 3D</h4>
              <ul>
                <li>Rendu Intérieur</li>
                <li>Visualisation</li>
                <li>Architectures VR</li>
              </ul>
            </div>
            <div className="stats_section_item">
              <h4 className="stats_section_item_title">Design</h4>
              <ul>
                <li>Design d'intérieur</li>
                <li>Conception architecturale</li>
                <li>Détail techniques</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="team_container">
        <div className="box_container">
          <div className="team_section">
            <h2>Notre équipe</h2>
            <div className="team-member-wraper">
              <div className="team-member" data-aos="zoom-in-left">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-right">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-down">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-up">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-left">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-right">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-down">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Serge MAHUGNON</div>{" "}
                  <div className="team-member-title text-muted">
                    Consultant SEO
                  </div>
                </div>
              </div>
              <div className="team-member" data-aos="zoom-in-up">
                <div className="team-member-img">
                  <img src={BgBanner} alt="" />
                  <ul>
                    <li>
                      <FaFacebookF />
                    </li>
                    <li>
                      <FaTwitter />
                    </li>
                    <li>
                      <FaLinkedin />
                    </li>
                  </ul>
                </div>
                <div className="team-member-desc">
                  <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                  <div className="team-member-title text-muted">
                    Spécialiste Marketing
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="container_section">
        <div className="box_container">
          <div className="container_wraper">
            <div className="container_content">
              <h2 className="animate__animated animate__bounce">
                Notre équipe
              </h2>
              <p className="animate__animated animate__bounce">
                Chez Vinctus Building Corporate, notre équipe est composée
                d'architectes talentueux et passionnés, déterminés à donner vie
                à vos projets les plus ambitieux. Chaque membre de notre équipe
                apporte son expertise unique et sa créativité à chaque projet,
                garantissant des résultats exceptionnels à chaque fois. Notre
                équipe est dévouée à offrir à nos clients une expérience
                exceptionnelle, de la conception initiale à la réalisation
                finale. Nous collaborons étroitement avec nos clients à chaque
                étape du processus, en veillant à ce que leurs besoins et leurs
                aspirations soient pleinement pris en compte.
              </p>
              <div style={{ marginTop: "30px" }}>
                <button
                  className="cta_btn"
                  onClick={() => navigate(routes.contact)}
                >
                  Contactez-nous
                </button>
              </div>
            </div>
            <div className="container_image">
              <img src={History} className="" />
            </div>
          </div>
        </div>
      </section>
      <section className="partner-section-container">
        <div className="box_container">
          <div className="partner-section">
            <div className="partner">
              <img src={BFR} alt="association_parlons_droit_et_societe" />
            </div>
            <div className="partner">
              <img src={BelOasis} alt="aa_consulting" />
            </div>
          </div>
        </div>
      </section>
      {projects.length > 0 && (
        <section className="client_container">
          <div className="box_container">
            <div className="client_section">
              <h2>Ce que disent nos clients</h2>
              <div className="slider-container">
                <Slider {...settings}>
                  {testimonies.map((testimony, index) => (
                    <div className="client_section_item" key={index}>
                      <div className="client_img">
                        <img
                          src={
                            testimony?.cover_img &&
                            process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              testimony?.cover_img
                          }
                          alt="client_image"
                        />
                      </div>
                      <h6 style={{ fontWeight: 300 }}>{testimony.fullname}</h6>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {testimony.title}
                      </span>
                      <p>{testimony.message}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Home;
