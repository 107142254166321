import axios from "axios";
class PlanService {
  getAll() {
    return axios.get(`/plans`);
  }
  get(id:number) {
    return axios.get(`/plans/${id}`);
  }
  create(data:any) {
    return axios.post(`/plans`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  update(id:number, data:any) {
    return axios.put(`/plans/${id}`, data);
  }
  delete(id:number) {
    return axios.delete(`/plans/${id}`);
  }
}
export default new PlanService();
