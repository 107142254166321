import axios from "axios"
class TestimonyService {
    getAll() {
        return axios.get(`/testimonies`);
    }
    get(id:number) {
        return axios.get(`/testimonies/${id}`);
    }
    create(data:any) {
        return axios.post(`/testimonies`, data);
    } 
    update(id:number, data:any) {
        return axios.put(`/testimonies/${id}`, data);
    }
    delete(id:number) {
        return axios.delete(`/testimonies/${id}`);
    }
}
export default new TestimonyService();