import axios from "axios";
class ProjectService {
  getAll() {
    return axios.get(`/projects`);
  }
  get(id:number) {
    return axios.get(`/projects/${id}`);
  }
  create(data:any) {
    return axios.post(`/projects`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  update(id:number, data:any) {
    return axios.put(`/projects/${id}`, data);
  }
  delete(id:number) {
    return axios.delete(`/projects/${id}`);
  }
}
export default new ProjectService();
