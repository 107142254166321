import Logo from "../assets/images/logo.jpeg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useState } from "react";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const toogleActive = () => {
    setActive((active) => {
      return !active;
    });
  };
  return (
    <div
      onContextMenu={() => setActive(false)}
      className="header_container"
      style={
        active
          ? {
              height: "100%",
            }
          : {}
      }
    >
      <div className="box_container">
        <div className="header_wraper">
          <Link
            to={routes.home}
            className="header_logo_container"
            onClick={() => setActive(false)}
          >
            <img src={Logo} alt="logo_vinctus_building_corporate" />
          </Link>
          <div className="header_links_container">
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.home}
              >
                Accueil
              </NavLink>
            </div>
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.about}
              >
                A propos
              </NavLink>
            </div>
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.catalogue}
              >
                Nos plans
              </NavLink>
            </div>
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.contact}
              >
                Contact
              </NavLink>
            </div>
            {/* 
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.project}
              >
                Nos projets
              </NavLink>
            </div> */}
          </div>
          <div className="header_links_container">
            <button
              className="cta_btn"
              onClick={() => navigate(routes.requestPlan)}
            >
              Commander un plan
            </button>
          </div>
          <div className="burger_menu" onClick={toogleActive}>
            <FaBars />
          </div>
        </div>
      </div>
      <div
        className="header_mobile_links_container"
        style={
          active
            ? {
                display: "block",
                height: "auto",
              }
            : {}
        }
      >
        <div className="header_mobile_links" onClick={() => setActive(false)}>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to={routes.home}
          >
            Accueil
          </NavLink>
        </div>
        <div className="header_mobile_links" onClick={() => setActive(false)}>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to={routes.about}
          >
            A propos
          </NavLink>
        </div>
        <div className="header_mobile_links" onClick={() => setActive(false)}>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to={routes.catalogue}
          >
            Nos plans
          </NavLink>
        </div>
        <div className="header_mobile_links" onClick={() => setActive(false)}>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to={routes.contact}
          >
            Contact
          </NavLink>
        </div>
        <div className="header_mobile_links" style={{ paddingBottom: "20px" }}>
          <button
            className="cta_btn"
            onClick={() => {
              setActive(false);
              navigate(routes.requestPlan);
            }}
          >
            Commander un plan
          </button>
        </div>
        {/* 
            <div className="header_links">
              <NavLink
                className={(nav) => (nav.isActive ? "nav-active" : "")}
                to={routes.project}
              >
                Nos projets
              </NavLink>
            </div> */}
      </div>
    </div>
  );
};

export default Header;
