import axios from "axios";
class CommentService {
  getAll() {
    return axios.get("/comments");
  }
  get(id:number) {
    return axios.get(`/comments/${id}`);
  }
  create(data:any) {
    return axios.post("/comments", data);
  }
  update(id:number, data:any) {
    return axios.put(`/comments/${id}`, data);
  }
  delete(id:number) {
    return axios.delete(`/comments/${id}`);
  }
}
export default new CommentService();
