import axios from "axios"
class InboxService {
    getAll() {
        return axios.get(`/inboxes`);
    }
    get(id:number) {
        return axios.get(`/inboxes/${id}`);
    }
    create(data:any) {
        return axios.post(`/inboxes`, data);
    } 
    update(id:number, data:any) {
        return axios.put(`/inboxes/${id}`, data);
    }
    delete(id:number) {
        return axios.delete(`/inboxes/${id}`);
    }
}
export default new InboxService();