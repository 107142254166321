export const routes = {
    home: "/",
    contact: "/nous-contacter",
    catalogue: "/nos-plans",
    catalogueDetail: "/nos-plans/:id",
    about: "/a-propos-de-nous",
    project: "/nos-projets",
    requestPlan: "/commander-un-plan",
    payment: "/paiement",
    terms: "/condition-d-utilisation",
    privacy: "/confidentialité",
    notFound: "*",
  };
  